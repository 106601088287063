body {
  margin: 0;
  font-family: "Corporate S", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  background-color: "none";
  border: none;
}

@font-face {
  font-family: "Corporate S";
  font-style: normal;
  font-weight: normal;
  src: url("../src/theme/fonts/corporate-S-Light-Regular.otf")
    format("truetype");
}

@font-face {
  font-family: "Corporate S";
  font-style: italic;
  font-weight: normal;
  src: url("../src/theme/fonts/corporate-s-light-italic.otf") format("truetype");
}

@font-face {
  font-family: "Corporate S";
  font-style: bold;
  font-weight: bold;
  src: url("../src/theme/fonts/corporates-demi.otf") format("truetype");
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: "transparent";
  border-radius: 15px;
}

::-webkit-scrollbar-track {
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #878784;
}

/* Firefox */
* {
  scrollbar-width: auto;
}

*::-moz-scrollbar-thumb {
  background-color: "transparent";
  border-radius: 15px;
}

*::-moz-scrollbar-thumb:hover {
  background-color: #878784;
  border-radius: 15px;
}

*::-moz-scrollbar-track {
  border-radius: 15px;
}
